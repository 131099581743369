.custom-select-container {
  min-width: 100px;
}

.border-error {
  border-radius: 8px;

  .custom-select {
    border: none !important;
  }
}

.custom-select {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  min-height: 40px;
  min-width: 100px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  .active-select-arrow {
    transform: rotate(180deg);
  }

  .more-text {
    padding-right: 5px;
    font-size: $caption-text;
    color: $primary-color;
  }

  input {
    flex: 1;
    width: fit-content;
    padding: 0 10px;
    font: $body-text $font-regular;
    color: $field-color;
    border: none;
    outline: none;
  }

  input:focus ~ span {
    color: $field-color;
  }
}

.custom-select-dropdown {
  position: fixed;
  width: 100%;
  /* max-height: 200px; */
  background-color: $white-color;
  border: 1px solid $secondary-color;
  border-radius: 8px;
  transition: max-height 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 2;
  margin-top: 5px;
  max-height: 300px;

  ul {
    margin: 0;
    padding: 0;
    background-color: $white-color;
    list-style-type: none;
  }

  li {
    padding: 9px;
    font: $body-text $font-regular;
    color: $primary-color;
    border-bottom: 1px solid $secondary-color;
    cursor: pointer;

    &.single-select-selected {
      color: $white-color;
      background-color: $primary-color;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  li:not(.single-select-selected):hover {
    background-color: $background-color;
  }

  li:not(.single-select-selected):focus {
    background-color: $background-color;
  }

  .selected-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white-color;
    background-color: $primary-color;

    span {
      padding-left: 10px;
      font-size: $body-text;
    }
  }

  .selected-list:hover,
  .selected-list:focus {
    background-color: $primary-hover-color;
    color: $primary-color;
  }
}

.country-select-dropdown {
  border: 1px solid $border-color;

  li,
  .custom-select__search {
    border-bottom: 1px solid $border-color;
  }
}

.custom-select-label {
  position: absolute;
  left: 8px;
  top: 9px;
  max-width: calc(100% - 16px);
  pointer-events: none;
  font: $body-text $font-regular;
  color: $placeholder-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 250ms;
  -webkit-user-select: none;
  user-select: none;
}

.custom-select.focus .custom-select-label {
  top: -12px;
  padding: 0 5px 0 5px;
  background: $white-color;
  font-size: $foot-note;
  color: $primary-color;
}

.border-error .custom-select .custom-select-label {
  top: -12px;
  padding: 0 5px 0 5px;
  background: $white-color;
  font-size: $foot-note;
  color: $error-color;
}

.custom-select__search {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 7px;
  background-color: white;
  border-bottom: 1px solid $secondary-color;
}

.custom-select__search-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px;
  border: 1px solid $border-color;
  border-radius: 5px;

  input {
    flex: 1;
    height: 20px;
    width: 100%;
    border: none;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .material-icons-outlined {
    margin-left: 10px;
    font-size: $header-3;
  }
}

.selected-first-value {
  padding-left: 8px;
  font: $body-text $font-regular;
  color: $primary-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-option-text {
  padding: 10px;
  font-size: $foot-note;
  color: $field-color;
  text-align: center;
}

.custom-select__search-icon {
  color: $border-color;
}

.custom-select__simple-placeholder,
.select-placeholder {
  padding: 0 10px;
  font: $body-text $font-regular;
  color: $placeholder-color;
}

.custom-select-close-icon {
  margin: 0 10px;
  padding: 2px;
  font-size: 12px;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 50%;

  &:hover {
    font-weight: 600;
    color: $error-hover-color;
    border-color: $error-hover-color;
  }
}

.width-small {
  .custom-select {
    cursor: default;
  }

  input,
  .custom-select {
    opacity: 0.5;
  }

  .material-icons-outlined {
    cursor: default;
  }
}

@media only screen and (max-width: 680px) {
  .custom-select-label {
    font: $foot-note $font-regular;
  }
  .custom-select-dropdown {
    position: static;
    z-index: 1;
  }
}
